* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
.container {
  background: url("./images/bg.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.container::after {
  content: "";
  /* background: rgba(0, 0, 0, 0.6); */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.page-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  position: relative;
  z-index: 2;
}

.page-heading h1 {
  font-size: 5rem;
  color: #fff;
  font-weight: 300;
  /* background: rgba(0, 0, 0, 0.5); */
  padding: 0px 20px;
  border-radius: 2px;
}

@media screen and (max-width: 600px) {
  .page-heading h1 {
    font-size: 3rem;
  }
}
