nav {
  background: rgba(108, 116, 118, 0.8);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  position: relative;
  z-index: 99;
}

.fas {
  cursor: pointer;
}

.menu-icon {
  display: none;
  font-size: 20px;
}
.menu-icon .fa-times {
  transform: rotate(360deg);
  transition: all 0.2s ease-in-out;
}

.menu-icon .fa-bars {
  transform: rotate(180deg);
  transition: all 0.2s ease-in-out;
}

.logo {
  font-weight: 600;
  font-size: 25px;
  color: rgba(206, 235, 251, 0.9);
}

.logo font {
  color: rgba(163, 214, 245, 0.9);
}

.menu-list {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-list li:last-child a {
  font-weight: 300;
  padding: 5px 10px;
  /* background-color: #01aacd; */
  transition: all 0.5s ease-in-out;
  margin-left: 20px;
  border-radius: 2px;
}

.menu-list li a {
  text-decoration: none;
  /* color: #fff; */
  color: rgba(206, 235, 251, 0.9);
  font-weight: 300;
  padding: 5px 10px;
  margin-left: 20px;
}

.menu-list li a:hover,
.menu-list li a.active {
  /* background-color: #fff;
   */
   background-color: rgba(163, 214, 245, 0.9);
  border-radius: 2px;
  color: #000;
  transition: all 0.5s ease-in-out;
}

@media screen and (max-width: 800px) {
  .logo {
    font-size: 20px;
  }
  .menu-list li a {
    font-size: 14px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 600px) {
  .menu-icon {
    display: block;
  }
  .menu-list {
    flex-direction: column;
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    padding: 20px;
    right: 0;
    top: 64px;
    transition: all 0.5s ease-in-out;
  }

  .menu-list li {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }
  .menu-list li:last-child {
    margin-bottom: 0px;
  }

  .menu-list li a,
  .menu-list li:last-child a {
    width: 100px;
    display: block;
    margin: auto;
  }

  .menu-list.close {
    right: -100%;
    transition: all 0.5s ease-in-out;
  }
}
